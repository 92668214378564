@import "_variables";
@import "_media";

* {
	box-sizing: border-box;
}

html {
	font-size: 10px;
}

html,
body {
	min-width: 320px;
}

body,
html,
#root,
.App {
	width: 100%;
}

body {
	font-family: "Montserrat", sans-serif;
	background-color: $beige;
	background-image: $background-gradient;
	margin: 0;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

a {
	text-decoration: none;
	color: black;

	&:hover {
		color: $purple;
	}
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

main {
	justify-content: center;
	flex-grow: 1;
	width: 100%;

	.mainContent {
		padding: 15px;
		margin: auto;
	}

	&.music {
		background-color: black;
	}

	&.video {
		background-color: $purple;
	}
}

.top-page {
	margin: -15px;
}

.image {
	max-width: 500px;
	margin: auto;
	width: 100vw;
}

.imgWrapper,
.logoWrapper {
	img {
		width: 100%;
	}
}

.imgWrapper {
	height: auto;
	margin: auto;
	padding-bottom: 100%;
	position: relative;

	img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		object-fit: cover;
		animation: slideUp 1s cubic-bezier(0.4, 0, 0.2, 1) 200ms backwards;
	}
}

.logoWrapper {
	width: 50%;
}

.logoWrapper,
.name,
.title {
	display: none;
}

.spotifyEmbed {
	margin: 30px 0;
	width: 100%;
}

.title {
	font-size: 4.5rem;
	text-align: center;
	text-transform: uppercase;
	color: $purple;
}

.outNow {
	font-size: 3rem;
	text-align: center;
	margin-bottom: 30px;
	font-weight: bold;
}

aside {
	margin-top: 15px;
	background-color: $beige;
	background-image: $background-gradient;
	position: relative;
	z-index: 10;
	padding: 15px;
}

.listen {
	text-align: center;
	font-size: 1.6rem;
}

button,
.linkAsBtn {
	padding: 15px 30px;
	font-size: 2rem;
	font-weight: bold;
	color: white;
	background: $purple;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

@include desktop {
	body {
		background-image: none;
	}

	main.music {
		display: flex;

		.mainContent {
			max-width: $max-width;
			margin: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex-grow: 1;
		}
	}

	.top-page {
		display: flex;
		flex-direction: row;
		min-height: calc(100vh - $header-desktop - 30px);
		margin: 0;
	}

	.image {
		max-width: calc(100vh - $header-desktop - 30px);
		height: 100%;
	}

	.imgWrapper {
		width: auto;
		display: flex;
		margin-left: 0;

		img {
			margin: auto;
			width: auto;
			max-height: calc(100vh - $header-desktop - 30px);
			border: solid 10px white;
			// position: static;
		}
	}
	aside {
		margin-left: 15px;
		margin-top: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0;
		background: none;
		width: 45%;
		flex-shrink: 0;
	}

	.name,
	.title {
		display: block;
	}

	.outNow {
		margin: 30px 0;
	}

	.spotifyEmbed {
		margin-top: 50px;
		margin-bottom: 0;

		iframe {
			height: 153px;
		}
	}
}

@include desktop--large {
	.spotifyEmbed iframe {
		height: 355px;
	}
}

@keyframes slideUp {
	from {
		transform: translateY(100%);
		opacity: 0;
	}

	to {
		transform: translateY(0%);
		opacity: 1;
	}
}
