@import '_variables.scss';

@mixin mobile-small {
	@media screen and (max-width: $breakpoint-mobile-small - 1) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: $breakpoint-tablet - 1) {
		@content;
	}
}

@mixin tablet {
	@media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: $breakpoint-desktop) {
		@content;
	}
}

@mixin desktop--large {
	@media screen and (min-width: $breakpoint-desktop--large) {
		@content;
	}
}

@mixin mobile-tablet {
	@media screen and (max-width: $breakpoint-desktop - 1) {
		@content;
	}
}

@mixin tablet-desktop {
	@media screen and (min-width: $breakpoint-tablet) {
		@content;
	}
}

@mixin landscape {
	@media screen and (orientation: landscape) {
		@content;
	}
}
