//Breakpoints
$breakpoint-mobile-min: 320px;
$breakpoint-mobile-small: 360px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop--large: 1200px;

//Colors
$pink: #edb2b4;
$beige: #f7f5e5;
$purple: #c23a96;
$lightgrey: #eee;
$background-gradient: linear-gradient(to right, #FCFCF4  42%, #F3F2DF);

$header-desktop: 100px;
$max-width: 1500px;