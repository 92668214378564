@import "_media";
@import "_variables";

.nav {
	display: flex;
	margin: auto;
	margin-top: 10px;

	ul,
	li {
		display: flex;
		align-items: center;
	}

	li:not(:last-child) {
		margin-right: 20px;
	}

	a {
		font-size: 2rem;
	}

	@include tablet-desktop {
		margin: 0;
		margin-left: auto;
	}
}

.current {
	color: $purple;
}