@import "_variables";
@import "_media";

.articleWrapper {
	border: solid 4px #fff;
	background: #fff;
	margin-bottom: 15px;
	box-shadow: 3px 3px 10px #ccc;

	.imgWrapper {
		overflow: hidden;
	}

	a {
		display: flex;
		flex-direction: column;
		height: 100%;
		text-decoration: none;
		overflow: hidden;

		&:hover {
			img {
				transform: scale(1.05);
				opacity: 0.7;
			}
		}
	}

	img {
		width: 100%;
		height: auto;
		max-height: 200px;
		object-fit: cover;
		object-position: top;
		flex-grow: 1;
		transition: all 500ms ease-in-out;
	}

	.details {
		padding: 10px 15px;
	}

	.title {
		display: block;
		color: $purple;
		font-size: 1.6rem;
		font-weight: bold;
	}

	.date {
		color: #000;
		text-transform: uppercase;
		margin-top: 5px;
		display: inline-block;
	}
}

@include tablet-desktop {
	.articleWrapper img {
		max-height: none;
	}
}
