@import "_variables";
@import "_media";

.header {
	display: flex;
	height: $header-desktop;
	background-color: white;
	justify-content: center;

	div {
		display: flex;
		max-width: $max-width;
		padding: 15px;
		width: 100%;
		flex-direction: column;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	h1 {
		color: $purple;

		&:hover {
			color: black;
		}
	}
}

@include tablet-desktop {
	.header div {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}
