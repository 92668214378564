@import "_media.scss";

.info {
	text-align: center;
	font-size: 1.4rem;
	margin-top: 50px;

	a {
		text-decoration: underline;
	}
}

.video {
	width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    margin: auto;
    max-width: 800px;
    display: flex;
}

@include tablet-desktop {
	.pressGrid {
		column-gap: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto;
		grid-template-areas: 
		"skytg tuttorock insidemusic"
		"headtopics endofacentury thesoundcheck";
	}
}
