.socialLinks {
	justify-content: flex-end;

	ul {
		display: flex;
		align-items: center;
		justify-content: center;

		li {
			height: 30px;
			max-width: 30px;
			margin: 10px 7px;
		}

		a {
			display: inline-block;
			height: 40px;
			color: black;

			&:hover {
				opacity: 0.8;
			}

			img,
			svg {
				height: 100%;
				width: 100%;
				object-fit: contain;
			}
		}
	}
}

footer {
	.socialLinks li {
		height: 20px;
		max-width: 20px;

		a {
			height: 20px;
		}
	}
}
