@import "_variables";
@import "_media";

.videoWrapper {
	display: flex;
	width: 100%;
	position: relative;
	aspect-ratio: 16 / 9;
	background-color: black;
	// border: solid 4px $purple;
	background: white;
	margin-bottom: 15px;
	flex-direction: column;
	max-width: 800px;
	margin: auto;

	&:not(:last-child) {
		margin-bottom: 30px;
	}
}

.videoDetails {
	color: $purple;
	display: block;
	font-size: 18px;
	font-weight: bold;
	padding: 20px;
	text-transform: uppercase;
}
