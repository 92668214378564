@import '_media.scss';

footer {
	text-align: center;
	font-size: 1.2rem;
	padding: 15px;

	span {
		display: inline-flex;
		align-items: center;
	}

	span:not(:last-child)::after {
		content: '•';
		display: inline-block;
		font-size: 2rem;
		padding: 0 5px;
		margin-bottom: 2px;
	}
}
